import React from 'react'
import styled from 'styled-components'
import AppImage from './images/app'
import MainImage from './images/main-image'
import HouseImage from './images/house'
import PhonesImage from './images/phones'
import RobotFacingRight from '../images/robot-facing-right'

const text = {
  title: 'Mapfit_',
  intro:
    ' needed a way to build upon their accurate mapping data & provide tools for the developer community to easily utilize their maps...',
  sectionOne:
    "We designed an application to gather information, add to Mapfit's data cleaning pipeline, & update the maps inside of an easy to use software development kit.",
  sectionTwoTitle: 'iOS Software Development Kit',
  sectionTwo:
    'We created a software development kit that allowed users to create maps within their own applications. Users can change map themes, polygon colors, add markers, & show directions along with many additional features.',
  sectionThreeTitle: 'Door Map Mobile App',
  sectionThree:
    'Our simple mobile app allowed delivery teams to easily refine doorway location data, identify secondary building entrances, & note pedestrian waypoints and loading docks. The data was validated by the Mapfit cloud and made available to Mapfit map users within minutes.',
}

const Landing = () => {
  return (
    <PageContainer>
      <Background>
        <IntroContainer>
          <IntroImageContainer>
            <MainImage />
          </IntroImageContainer>
          <IntroTextContainer>
            <IntroTitle>{text.title}</IntroTitle>
            <IntroDetail>
              <StyledLink
                href="https://techcrunch.com/2018/06/12/mapfit-raises-5-5m-for-its-mapping-platform/"
                target="_blank"
              >
                Mapfit
              </StyledLink>
              {text.intro}
            </IntroDetail>
          </IntroTextContainer>
        </IntroContainer>
      </Background>
      <Section>
        <TextContainer>{text.sectionOne}</TextContainer>
        <ImageContainer width="60%">
          <HouseImage />
        </ImageContainer>
      </Section>
      <Section>
        <SectionTitle>{text.sectionThreeTitle}</SectionTitle>
        <TextContainer>{text.sectionThree}</TextContainer>
        <ImageContainer width="65%">
          <AppImage />
        </ImageContainer>
      </Section>
      <Background color="white">
        <TestImageRight>
          <RobotImageContainerRight>
            <RobotFacingRight />
          </RobotImageContainerRight>
        </TestImageRight>
        <Section>
          <SectionTitle>{text.sectionTwoTitle}</SectionTitle>
          <TextContainer>{text.sectionTwo}</TextContainer>
          <ImageContainer width="80%">
            <PhonesImage />
          </ImageContainer>
        </Section>
      </Background>
    </PageContainer>
  )
}

export default Landing

const StyledLink = styled.a`
  color: #f16faa;
  :hover {
    color: white;
  }
`

const TestImageRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  margin: 0px auto;
  overflow: hidden;
`

const RobotImageContainerRight = styled.div`
  position: absolute;
  width: 35%;
  top: 30%;
  left: -25%;
`

const PageContainer = styled.div`
  text-align: center;
  background: white;
  color: black;
  font-family: 'Helvetica Neue';
  line-height: 1.5;

  margin: 0px auto;
`

const Background = styled.div`
  background-color: ${props => props.color || 'black'};
  width: 100vw;
  position: relative;
`

const IntroContainer = styled.div`
  background: black;
  color: white;
  display: flex;
  padding: 20px 0px;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    padding: 50px 0px;
  }
`
const IntroImageContainer = styled.div`
  width: 50%;
  padding-left: 3%;
  padding-top: 20px;
`

const IntroTitle = styled.div`
  font-size: 36px;
  font-weight: 500;

  @media screen and (min-width: 375px) {
    font-size: calc(36px + (100 - 36) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 100px;
  }
`

const IntroDetail = styled.div`
  font-size: 10px;
  padding-right: 10px;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (28 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const IntroTextContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 3%;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`
const TextContainer = styled.div`
  font-size: 12px;
  color: #707070;
  width: 75%;
  margin-bottom: 20px;
  font-weight: 300;
  max-width: 800px;
  padding: 2% 0 3%;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
const ImageContainer = styled.div`
  width: ${props => props.width || '85%'};
  margin: 0px auto;
`

const SectionTitle = styled.div`
  font-weight: bold;

  @media screen and (min-width: 375px) {
    font-size: calc(12px + (32 - 12) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 32px;
  }
`
