import React from 'react'
import Mapfit from '../../components/portfolio/mapfit/index'
import SEO from '../../components/seo'

const MapfitPage = () => (
  <>
    <SEO title="Tabreezi Portfolio" />
    <Mapfit />
  </>
)

export default MapfitPage
